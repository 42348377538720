import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Header,
  Icon,
  Modal,
  Segment,
  Dimmer,
  Loader,
  Button
} from "semantic-ui-react";
import CardNurseNote from "./CardNurseNote";
import CardProgressNote from "./CardProgressNote";
import { convertToBEDate } from "../../../utils/tsUtils";
import moment from "moment";
import config from "../../../configs/settings";

const CardPatientPanel = React.forwardRef((props, ref) => {
  const [openNurseNote, setOpenNurseNote] = useState(false);
  const [openProgressNote, setOpenProgressNote] = useState(false);
  const [classify, setClassify] = useState("");

  const openModalNurseNote = () => {
    setOpenNurseNote(true);
  };

  const closeModalNurseNote = () => {
    setOpenNurseNote(false);
  };

  const openModalProgressNote = () => {
    setOpenProgressNote(true);
  };

  const closeModalProgressNote = () => {
    setOpenProgressNote(false);
  };

  useEffect(() => {
    if (props.encounterData.triage_level && props.triageLevelList.length > 0) {
      let item = props.triageLevelList.find(
        item => item.value === props.encounterData.triage_level
      );
      if (item) {
        setClassify(item.text);
      } else {
        setClassify(props.encounterData.triage_level);
      }
    } else {
      setClassify(props.encounterData.triage_level);
    }
  }, [
    props.triageLevelList,
    props.encounterData && props.encounterData.triage_level
  ]);
  return (
    <div className="PatientPanel">
      <Dimmer.Dimmable>
        <Segment className="main-segment" textAlign="center">
          {!props.hideClassify && <Header as="h5">Classify : {classify}</Header>}

          <Icon name="user circle" inverted size="massive" />

          <Header.Subheader as="h4">
            {`${props.patientData.first_name ? props.patientData.first_name : "Unknown"} ${props.patientData.middle_name ? props.patientData.middle_name : ""} ${props.patientData.last_name ? props.patientData.last_name : ""}`}
          </Header.Subheader>
          <Header.Subheader as="h5" className="left-align">
            HN : {props.patientData.hn}
          </Header.Subheader>
          <Header.Subheader as="h5" className="left-align">
            อายุ : {props.patientData.age}
          </Header.Subheader>
          <Header.Subheader as="h5" className="left-align">
            วันเกิด :{" "}
            {props.patientData.birthdate
              ? convertToBEDate({ date: moment(props.patientData.birthdate, ["DD/MM/YYYY", "YYYY-MM-DD"]).format("DD/MM/YYYY") })
              : "-"}
          </Header.Subheader>
          <Header.Subheader as="h5" className="left-align">
            โทร : {props.patientData.phone_no}
          </Header.Subheader>
          {/* <Header.Subheader as="h5" className="left-align">
          Line ID : {props.patientData.line_id}
        </Header.Subheader> */}
          <Header.Subheader as="h5" className="left-align">
            E-mail : {props.patientData.email}
          </Header.Subheader>
          <Header.Subheader as="h5" className="left-align">
            ที่อยู่ : {props.patientData.address}
          </Header.Subheader>

          {!props.hideNote && (<Segment attached>
            <Header as="h5" className="inverted">
              Notes
            </Header>
            <Segment className="note-bg">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <div>
                  <Header as="h5" className="inverted">
                    Nurse’s Note
                  </Header>
                  <Header.Subheader>
                    {props.patientData.nurse_note}
                  </Header.Subheader>
                </div>
                <div>
                  <Icon name="pencil" size="big" onClick={openModalNurseNote} />
                </div>
              </div>
            </Segment>
            <Segment className="note-bg">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {" "}
                <div>
                  <Header as="h5" className="inverted">
                    Progress Note
                  </Header>
                  <Header.Subheader>
                    {props.patientData.progress_note}
                  </Header.Subheader>
                </div>
                <div>
                  <Icon
                    name="pencil"
                    size="big"
                    onClick={openModalProgressNote}
                  />
                </div>
              </div>
            </Segment>
          </Segment>)}
          <div style={{ marginTop: "40px" }}>
            {
              props.showPatientProfileDownload &&
              <Button className="btn-download" style={{ marginLeft: "20px", marginBottom: "13px" }}>
                Patient Profile <Icon name="download" style={{ marginLeft: "10px" }} />
              </Button>
            }
            {
              props.showConsentFormDownload &&
              <Button
                className="btn-download"
                style={{ marginLeft: "20px", backgroundColor: "#2D9BE2" }}>
                Consent form <Icon name="download" style={{ marginLeft: "10px" }} />
              </Button>
            }
          </div>
        </Segment>

        <Modal open={openNurseNote}>
          <CardNurseNote
            hideCallback={closeModalNurseNote}
            controller={props.nurseNoteController}
            apiToken={props.apiToken}
            encounterId={props.encounterData.id}
            division={props.division}
            readOnly={props.readOnly}
            languageUX={props.languageUX}
          />
        </Modal>

        <Modal open={openProgressNote}>
          <CardProgressNote
            hideCallback={closeModalProgressNote}
            controller={props.progressNoteController}
            apiToken={props.apiToken}
            encounterId={props.encounterData.id}
            emrId={props.encounterData.medical_record}
            readOnly={props.readOnly}
            languageUX={props.languageUX}
          />
        </Modal>
        <Dimmer active={props.loading} inverted>
          <Loader inverted>Loading...</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  );
});

CardPatientPanel.defaultProps = {
  controller: null,
  apiToken: null,
  patientData: {},
  encounterData: {},
  division: null,
  loading: false,
  readOnly: false,
  triageLevelList: [],
  hideClassify: false,
  hideNote: false,
  showPatientProfileDownload: false,
  showConsentFormDownload: false
};

CardPatientPanel.propTypes = {
  controller: PropTypes.object,
  apiToken: PropTypes.string,
  patientData: PropTypes.object,
  encounterData: PropTypes.object,
  division: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  triageLevelList: PropTypes.array,
  hideClassify: PropTypes.bool,
  hideNote: PropTypes.bool,
  showPatientProfileDownload: PropTypes.bool,
  showConsentFormDownload: PropTypes.bool

};

export default React.memo(CardPatientPanel);

import React, { useEffect, useState } from "react";
import { useHistory, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import SwipeableViews from "react-swipeable-views";
import Cookies from "js-cookie";
import { PatientData } from "./Model";
import { makeStyles } from "@mui/styles";

import {
  Dimmer,
  Loader,
  Menu,
  Tab,
  TabProps,
  Button,
  Icon,
} from "semantic-ui-react";
import { serial_to_datestr, PatientAppointment, QueueController } from "./Time";
import CardPatientAppointment from "./CardPatientAppointment";
import { FormattedMessage } from "react-intl";

const COLOR = {
  primary: "var(--primary-theme-color)",
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  },
  tabActive: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    color: COLOR.primary + "!important",
    borderColor: COLOR.primary + "!important",
  },
  absoluteBottomContent: {
    position: "fixed",
    bottom: "10%",
    width: "100%",
    textAlign: "center",
  },
  absoluteNoneAppointment: {
    position: "absolute",
    bottom: "55%",
    fontSize: "22px",
    color: "#746A6A",
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
  },
}));

interface RouterProps {}
interface PaientAppointmentPatientProps
  extends RouteComponentProps<RouterProps> {
  apiToken?: string;
  patientData?: PatientData;
  controller?: QueueController;
  handleGoBack?: any;
  noApiToken?: any;
  header?: any;
  displayDatetimeIso?: boolean;
  onMakeAppointment?: any;
  setProps?: any;
  onEvent?: any;
  patientAppointmentList?: any;
  newUI?: boolean;
}

const PaientAppointmentPatient = (props: PaientAppointmentPatientProps) => {
  const history = useHistory();
  const classes = useStyles();
  const isMounted = React.useRef(true);
  const swipeableRef = React.useRef<any>();

  const [patientAppointmentList, setPatientAppointmentList] = useState<any[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewIndex, setViewIndex] = useState<any>(0);

  useEffect(() => {
    if (!props.apiToken && !Cookies.get("apiToken")) {
      props.noApiToken();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.patientData?.id) {
      handleListPatientAppointment();
    } else {
      setIsLoading(false);
    }
  }, [props.patientData?.id, viewIndex]);

  useEffect(() => {
    if (isMounted.current) {
      setIsLoading(false);
      if (props.patientAppointmentList) {
        let newArr: PatientAppointment[] =
          props.patientAppointmentList?.items?.sort(
            (
              a: { estimated_at_iso: string },
              b: { estimated_at_iso: string }
            ) => {
              if (!a.estimated_at_iso && !b.estimated_at_iso) return 0;
              else if (!a.estimated_at_iso) return -1;
              else if (!b.estimated_at_iso) return 1;
              else
                return (
                  moment(a.estimated_at_iso).valueOf() -
                  moment(b.estimated_at_iso).valueOf()
                );
            }
          ).filter((item:any)=> item.status !== 4)

        setPatientAppointmentList(newArr);
      } else {
        setPatientAppointmentList([]);
      }
    }
  }, [props.patientAppointmentList]);

  const panes = React.useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item
            key="currentAppointment"
            className={viewIndex === 0 ? classes.tabActive : classes.tabs}
          >
            <FormattedMessage id="appoint.appointment_list" />
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item
            key="historyAppointment"
            className={viewIndex === 1 ? classes.tabActive : classes.tabs}
          >
            <FormattedMessage id="appoint.appointment_history" />
          </Menu.Item>
        ),
      },
    ],
    [viewIndex]
  );

  const handleGetPatientAppointmentList = () => {
    props.onEvent({
      message: "HandlePatientAppointmentView",
      params: {
        patientId: props.patientData?.id,
        viewIndex: viewIndex,
      },
    });
  };

  const handleListPatientAppointment = async () => {
    setIsLoading(true);
    handleGetPatientAppointmentList();
    // const [response, error, network]: any =
    //   await props.controller?.listPatientAppointment({
    //     apiToken: props.apiToken ? props.apiToken : Cookies.get("apiToken"),
    //     patientId: props.patientData?.id,
    //     status: viewIndex === 1 ? 8 : null,
    //   });
    // if (isMounted.current) {
    //   setIsLoading(false);
    //   if (props.patientAppointmentList) {
    //     let newArr: PatientAppointment[] =
    //       props.patientAppointmentList?.items.sort(
    //         (
    //           a: { display_info: { start_serial: number } },
    //           b: { display_info: { start_serial: number } }
    //         ) => {
    //           if (!a.display_info && !b.display_info) return 0;
    //           else if (!a.display_info) return -1;
    //           else if (!b.display_info) return 1;
    //           else
    //             return (
    //               a.display_info.start_serial - b.display_info.start_serial
    //             );
    //         }
    //       );

    //     console.log(" newArr", newArr);
    //     setPatientAppointmentList(newArr);
    //   } else {
    //     setPatientAppointmentList([]);
    //   }
    // }
  };

  const handleCancelPatientAppointment = async (item: any) => {
    console.log("handleCancelPatientAppointment item >>> ", item.id);
  };

  const handleEditPatientAppointment = (item: any) => {
    console.log("handleEditPatientAppointment item >>> ", item.id);
    if (props.newUI) {
      history.push(`/editAppointmentIH/${item.id}`);
    } else {
      history.push(`/tuh-transform/EditAppointmentIH/${item.id}`);
    }
    props.onEvent({
      message: "HandleEditAppointment",
    });
  };

  const handleEditAppointmentIH = () => {
    if (props.newUI) {
      history.push(`/appointmentIH`);
    } else {
      history.push(`/tuh-transform/AppointmentIH`);
    }
  };

  return (
    <div className={props.newUI ? "content-detail" : "TimePatient"}>
      <div style={{ padding: "17.5px" }}>
        {/* {props.header} */}
        <Dimmer.Dimmable style={{ padding: "0px" }}>
          <Dimmer active={isLoading} inverted>
            <Loader inverted />
          </Dimmer>
          <Tab
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={viewIndex}
            onTabChange={(event: any, data: TabProps) => {
              setViewIndex(data.activeIndex);
            }}
          />
          <SwipeableViews
            ref={swipeableRef}
            animateTransitions={true}
            index={viewIndex}
            onChangeIndex={(index: number) => {
              setViewIndex(index);
            }}
            style={{
              marginTop: "20px",
              width: props.newUI ? "" : "100vw",
              paddingBottom: "120px",
            }}
          >
            {/* current patient appointment */}
            <CardPatientAppointment
              editAble={true}
              patientAppointmentList={patientAppointmentList}
              handleGoBack={props.handleGoBack}
              onEdit={handleEditPatientAppointment}
              onCancel={handleCancelPatientAppointment}
              setProps={props.setProps}
              onEvent={props.onEvent}
              getPatientAppointmentList={handleGetPatientAppointmentList}
              patientId={props.patientData?.id}
              viewIndex={viewIndex}
              newUI={props.newUI}
              languageUX={props.languageUX}
            />
            {/* patient appointment history */}
            <CardPatientAppointment
              editAble={false}
              patientAppointmentList={patientAppointmentList}
              handleGoBack={props.handleGoBack}
              onEdit={handleEditPatientAppointment}
              onCancel={handleCancelPatientAppointment}
              setProps={props.setProps}
              onEvent={props.onEvent}
              getPatientAppointmentList={handleGetPatientAppointmentList}
              patientId={props.patientData?.id}
              viewIndex={viewIndex}
              newUI={props.newUI}
              languageUX={props.languageUX}
            />
          </SwipeableViews>

          {props.newUI ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                left: "50%",
                transform: "translate(-50%, 0)",
                bottom: "75px",
              }}
            >
              <div
                className="content-button"
                // inverted
                onClick={() => {
                  //   props.onMakeAppointment?.();
                  props.setProps(`appointmentData.detail`, "")
                  handleEditAppointmentIH();
                }}
              >
                <Icon name="plus" style={{ color: "whith" }} />
                สร้างรายการนัดหมายใหม่
              </div>
            </div>
          ) : (
            <div className={classes.absoluteBottomContent}>
              <Button
                color="blue"
                // inverted
                style={{
                  borderRadius: "5px",
                  color: COLOR.primary,
                  backgroundColor: "white",
                  border: `1px solid ${COLOR.primary}`,
                }}
                onClick={() => {
                  //   props.onMakeAppointment?.();
                  handleEditAppointmentIH();
                }}
              >
                <Icon name="plus" style={{ color: COLOR.primary }} />
                สร้างรายการนัดหมายใหม่
              </Button>
            </div>
          )}

          {!isLoading && patientAppointmentList?.length === 0 && (
            <div className={classes.absoluteNoneAppointment}>
              <FormattedMessage id="appoint.no_appointment" />
            </div>
          )}
        </Dimmer.Dimmable>
      </div>
    </div>
  );
};

PaientAppointmentPatient.defaultProps = {
  patientData: {},
  controller: {},
  noApiToken: () => {},
  onMakeAppointment: () => {},
  header: {},
  displayDatetimeIso: false,
  match: null,
  newUI: false,
};

export default PaientAppointmentPatient;

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  Dropdown,
  Form,
  Grid,
  Dimmer,
  Loader
} from "semantic-ui-react";
import RightLabel from "../../common/RightLabel";
import Cookies from "js-cookie";
import ErrorMessage from "react-lib/apps/common/ErrorMessage";

const style = {
  root: {
    width: "100%",
    height: "100%"
  },
  noPadding: {
    padding: "0px"
  },
  noMargin: {
    margin: 0
  },
  formPadding: {
    padding: "5%"
    // padding: "30px 0px"
  },
  button: {
    borderRadius: 0
  }
};

const CardClassify = props => {
  const isMounted = useRef(true);
  const [triageLevelList, setTriageLevelList] = useState([]);
  const [triageLevel, setTriageLevel] = useState("");
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, []);

  React.useEffect(() => {
    const getTriageLevelClassify = async () => {
      const [
        response,
        error,
        network
      ] = await props.controller.getTriageLevelClassify({
        apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
        divisionId: props.division,
      });

      if (response) {
        let options = formatTriageLevelClassifyDropdown(
          response.classify,
          "description",
          "triage_level",
          "active"
        );
        setTriageLevelList(options);
      } else {
        setTriageLevelList([]);
      }
    };
    if (props.division) {
      getTriageLevelClassify();
    }
  }, [props.division])

  const formatTriageLevelClassifyDropdown = (
    optionsList,
    textField,
    keyField,
    activeField
  ) => {
    if (optionsList && Array.isArray(optionsList)) {
      optionsList.forEach(items => {
        items.key = items[keyField];
        items.text = items[textField];
        items.value = items[keyField];
        if (activeField) {
          items.active = items[activeField];
        }
        delete items["description"]
        return items;
      });
      return optionsList;
    } else {
      return [];
    }
  };

  const handleTriageLevel = (event, data) => {
    setTriageLevel(data.value);
  };

  const handleUpdateTriage = async () => {
    if (!triageLevel) {
      return setError("กรุณาเลือก classify");
    }
    setError(null);
    setIsLoading(true);

    const params: any = {
      apiToken: Cookies.get("apiToken") ? Cookies.get("apiToken") : props.apiToken,
      encounterId: props.encounterData.id,
      triageLevel: triageLevel
    }

    if (props.diagFormId) {
      params.diagFormId = props.diagFormId
    }

    const [response, error, network] = await props.controller.updateTriage(params);
    setIsLoading(false);
    if (isMounted.current) {
      if (error) {
        if (error.action) {
          setError(error.action.message);
        }
      } else if (response) {
        props.onSuccess(response)
        props.hideCallback();
      }
    }
  };

  return (
    <Dimmer.Dimmable dimmed={isLoading} style={{ height: "100%" }}>
      <Dimmer active={isLoading} inverted>
        <Loader inverted size="massive">
          Loading...
        </Loader>
      </Dimmer>
      <div style={style.root}>
        <Form style={style.formPadding}>
          <ErrorMessage error={error} />
          <Form.Group inline>
            <Form.Field width={6}>
              <RightLabel>กรุณาระบุ Classify</RightLabel>
            </Form.Field>
            <Form.Field width={10}>
              <Dropdown
                selection
                options={triageLevelList}
                value={triageLevel}
                onChange={handleTriageLevel}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Form>
          <Form.Group inline style={style.noMargin}>
            <Form.Field
              style={{ ...style.noMargin, ...style.noPadding }}
              width={8}
            >
              <Button
                style={style.button}
                fluid
                color="red"
                onClick={props.hideCallback}
              >
                ยกเลิก
              </Button>
            </Form.Field>
            <Form.Field
              style={{ ...style.noMargin, ...style.noPadding }}
              width={8}
            >
              <Button
                style={style.button}
                fluid
                color="green"
                onClick={handleUpdateTriage}
              >
                ยืนยัน
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
      </div>
    </Dimmer.Dimmable>
  );
};

CardClassify.defaultProps = {
  controller: null,
  hideCallback: () => { },
  encounterData: {},
  onSuccess: () => { },
  diagFormId: null
};

CardClassify.propTypes = {
  controller: PropTypes.object,
  hideCallback: PropTypes.func,
  encounterData: PropTypes.object,
  onSuccess: PropTypes.func,
  diagFormId: PropTypes.number
};

export default React.memo(CardClassify);

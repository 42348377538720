import { waiting } from "./../react-lib/apps/ER/StandingOrderHandler";
import WasmController from "../react-lib/frameworks/WasmController";
import moment from "moment";
// @ts-ignore
import Cookies from "js-cookie";

// APIs
import RegisterAPI from "issara-sdk/apis/RegisterAPI_users";
import CheckAvailableAPIView from "../issara-sdk/apis/CheckAvailableAPIView_usersM";
import CheckAvailableCIDAPIView from "../issara-sdk/apis/CheckCidAvailableAPI_users";
import RequestToken from "../issara-sdk/apis/RequestTokenAPI_users";
import UserProfileAPI from "../issara-sdk/apis/ProxyUserProfileAPI_users";
// import MobileRequestOTPAPI from "issara-sdk/apis/RequestOTPAPI_users";
import ProfileApi from "issara-sdk/apis/ProxyMyProfile_profile_apiM";
import RequestTokenWithEmail from "issara-sdk/apis/RequestTokenWithEmailVerificationAPI_users";
import MobileRequestTokenAPI from "issara-sdk/apis/RequestTokenAPI_users";
// import UserSetPasswordView from "issara-sdk/apis/UserSetPasswordView_users";
import CheckRequireDiagRule from "issara-sdk/apis/CheckRequireDiagRuleView_apps_PRX";
import MobileDeviceSignOutAPI from "issara-sdk/apis/MobileDeviceSignOutAPI_users";
import PatientAppointmentView from "issara-sdk/apis/PatientAppointmentView_apps_QUE";
import ProxyPatientEmergencyContactView from "issara-sdk/apis/ProxyPatientEmergencyContactView_apps_PRX";
import DoctorDivisionServiceBlockSchedule from "issara-sdk/apis/DoctorDivisionServiceBlockSchedule_apps_QUE";
import PatientAppointmentUpdate from "issara-sdk/apis/PatientAppointmentUpdate_apps_QUE";
import DoctorDetail from "issara-sdk/apis/DoctorDetail_core";
import MyProxyPatientView from "../issara-sdk/apis/MyProxyPatientView_apps_PRX";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DoctorList from "issara-sdk/apis/DoctorList_core";
import DivisionScheduleTimeList from "issara-sdk/apis/DivisionScheduleTimeList_apps_QUE";
import ProxyUserProfileAPI from "issara-sdk/apis/ProxyUserProfileAPI_users";
import ResetPasswordAPI from "issara-sdk/apis/ResetPasswordAPIView_users";
import AutoMatchORCreateHNFromCID from "issara-sdk/apis/AutoMatchORCreateHNFromCID_apps_PRX";
import GetDataForOtp from "issara-sdk/apis/GetDataForOTPAPI_apps_PRX";
import UserChangePasswordAPI from "issara-sdk/apis/UserChangePasswordAPI_users";
import AppointmentDetail from "issara-sdk/apis/AppointmentDetail_apps_APP";
import DoctorDSBAvailableList from "issara-sdk/apis/DoctorDSBAvailableList_apps_QUE"
import DivisionServiceBlockAvailableList from "issara-sdk/apis/DivisionServiceBlockAvailableList_apps_QUEM"

//type
import {
  registerInfo,
  profileInfo,
  LoginInfoInfo,
  dropDownOption,
  DevModeType,
} from "react-lib/apps/IsHealth/IHMobile/TypeModal";

// @ts-ignore
import Fingerprint2 from "fingerprintjs2";
import {
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from "../react-lib/apps/IsHealth/Common/push-notifications";

// Utils
import {
  dateToStringWithoutTimeBE,
} from "../react-lib/utils/dateUtils";
// Utils

export type State = {
  theme?: string;
  projectName?: string;
  device_id?: string | number;
  subscription?: any;
  uiState?: "LOGIN" | "REGISTER" | "CONSENT";
  apiToken?: string;
  userId?: string | number;
  loggedin?: boolean;
  language?: "en-US" | "th-TH";
  login?: boolean;
  devMode?: DevModeType
  profile?: boolean;
  openVideoCallModal?: false;
  videoCallRoom?: string;
  dropDownOption?: dropDownOption;
  refCode?: string;
  loading?: boolean;
  systemType?: "IOS" | "ANDROID";
  setDevice?: Partial<{
    device_id: number;
    device_type: string;
    device_name: string;
    device_token: string;
    device_api_version: string;
    application: string;
  }> | null;

  // Data
  loginInfo?: LoginInfoInfo;

  viewIndexRegister?: number;
  profileInfo?: profileInfo;
  registerInfo?: registerInfo;
  phoneNumber?: string;
  chatSearchValue?: string;
  channelList?: any;
  chatLoading?: boolean;
  chatDivision?: string | undefined;
  errorCheck?: boolean;
  errorMessage?: any;
  initialInnerScreen?: any;
  patientData?: any;
  appointmentData?: any;
  createAppointment?: {
    divisionScheduleTimeList?: any;
    doctorDivisionServiceBlockSchedule?: any;
    userProfile?: any;
  };
  patientAppointmentList?: any;
  divisionList?: any;
  doctorList?: any;
  timeDivision?: any[];
  consentFinished?: boolean;
  chatUnread?: number;
  duplicateAPMList?: any[];
};

export const StateInitial: State = {
  language: ["th", "th-TH", "th-th"].includes(
    Cookies.get("language") || navigator?.language?.split(/[-_]/)[0]
  )
    ? "th-TH"
    : "en-US",
  // language: "th-TH",
  refCode: "",
  // Data
  loading: false,
  viewIndexRegister: 0,
  setDevice: null,
  loginInfo: {
    username: "",
    password: "",
    errorMessage: {},
  },
  devMode: {},
  uiState: "LOGIN",
  consentFormLang: "",
  profileInfo: {},
  registerInfo: {
    cid: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    birthdate: "",
    hn: "",
    hidePassword: true,
    hideConfirmPassword: true,
    error: {
      cid: null,
      email: null,
      username: null,
      password: null,
      confirmPassword: null,
      firstName: null,
      lastName: null,
      birthdate: null,
      hn: null,
      message: "",
      viewIndex: 0,
    },
  },
  phoneNumber: "",
  chatSearchValue: "",
  channelList: [],
  chatLoading: false,
  chatDivision: "",
  errorCheck: false,
  errorMessage: "",
  initialInnerScreen: "",
  patientData: {},
  appointmentData: {},
  createAppointment: {
    divisionScheduleTimeList: [],
    doctorDivisionServiceBlockSchedule: [],
    userProfile: "",
  },
  patientAppointmentList: {},
  divisionList: [],
  doctorList: [],
  timeDivision: [],
  consentFinished: false,
  chatUnread: 0,
  duplicateAPMList: []
};

const required = {
  username: {
    input: ["username"],
    check: {
      prefix: ["A-Z", "a-z", "0-9"],
      minLength: 8,
      message: "โปรดระบุชื่อผู้ใช้ให้ตรงเงื่อนไขที่ระบุ",
    },
  },
  password: {
    input: ["password", "confirmPassword"],
    check: {
      prefix: ["A-Z", "a-z", "0-9"],
      minLength: 8,
      message: "โปรดระบุรหัสผ่านผู้ใช้ให้ตรงเงื่อนไขที่ระบุ",
    },
  },
  info: {
    input: ["firstName", "lastName", "birthdate"],
    check: { prefix: [] },
  },
  hn: {
    input: ["hn"],
    check: {
      prefix: ["0-9"],
      length: 7,
    },
  },
  phoneNum: {
    input: ["phoneNum"],
    check: {
      prefix: ["0-9"],
      length: 10,
    },
  },
  cid: {
    input: ["cid"],
    check: {
      prefix: ["0-9"],
      length: 13,
      message: "cid ไม่ตรงตามเงื่อนไข",
    },
  },
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "DidUpdate"; params: {} }
  | {
      message: "HandleSetValueRegisterInfo";
      params: { key: string; value: any };
    }
  | {
      message: "HandleRegister";
      params: { viewRegister: string; history: any };
    }
  | {
      message: "HandleResetPassword";
      params: { history: any , email: any};
    }
  | {
      message: "HandleRequestTokenWithEmail";
      params: { history: any };
    }
  | {
      message: "HandleRequestToken";
      params: {};
    }
  | {
      message: "HandleVerifyOTP";
      params: {
        ref: string;
        codeOtp: string;
        device_id: string;
        device_type: string;
        device_name: string;
        device_token: string;
      };
    }
  | { message: "HandleSetProfileInfo"; params: { key: string; value: any } }
  | { message: "HandleSetLoginInfo"; params: { key: string; value: any } }
  | { message: "HandleLogin"; params: { history: any } }
  | { message: "HandleUpdateUserProfile"; params: { history: any } }
  | {
      message: "HandleSetInitialInnerScreen";
      params: { height: number; width: number };
    }
  | { message: "GetPatientDetail"; params: {} }
  // appointment
  | { message: "HandleDivisionScheduleTimeList"; params: {} }
  | { message: "HandleDoctorDivisionServiceBlockSchedule"; params: {} }
  | {
      message: "HandleChangePasswordApi";
      params: {
        password?: string;
        new_password?: string;
        confirm_password?: string;
      };
    }
  | { message: "HandleGetTimeSlotByDate"; params: { selectDate?: string } }
  | { message: "HandleDivisionList"; params: {} }
  | { message: "HandleDoctorList"; params: {} }
  | { message: "HandleCreateAppointment"; params: {} }
  | { message: "HandleGetAppointment"; params: {} }
  | { message: "HandleUpdateAppointment"; params: {} }
  | { message: "HandleUpdateProfileImage"; params: {} }
  | { message: "HandleAutoHn"; params: { history: any } }
  | {
      message: "HandleCancelAppointment";
      params: { id: any; patientId: any; viewIndex: any };
    }
  | {
      message: "HandlePatientAppointmentView";
      params: { patientId: any; viewIndex: any };
    }
  | {
      message: "HandleGetUserProfile";
      params: { history: any; loading: string; lang: any };
    }
  | {
      message: "HandleCheckConsent";
      params: { history: any; readOnly?: any };
    }
  | { message: "HandleSignOut"; params: {} };

export type Data = {};

export const DataInitial = {};

const MOR_COMPANY = "mor.company";

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const DidMount: Handler = async (controller, params) => {};

export const DidUpdate: Handler = async (controller, params) => {};

export const HandleSetInitialInnerScreen: Handler = (controller, params) => {
  controller.setState({ initialInnerScreen: params });
};

export const HandleRegister: Handler = async (controller, params) => {
  const { registerInfo } = controller.getState();
  let { viewRegister, history } = params;
  let isError = false;
  let errorPassword = "โปรดระบุรหัสผ่านผู้ใช้ให้ตรงเงื่อนไขที่ระบุ";
  let errorPasswordCompare = "รหัสผ่านไม่ตรงกัน";
  let errorIntersectUsername = "รหัสผ่านนี้คล้ายกับ ชื่อผู้ใช้งาน มากเกินไป";
  let errorCheckCid = "กรุณาระบุข้อมูลให้ถูกต้อง"
  let errorCheckPassNo = "Passport ไม่ถูกต้องตามเงื่อนไข"

  controller.setState({
    uiState: "REGISTER",
  });

  const checkUsername = checkValidInput({
    ...required["username"].check,
    text: registerInfo?.username || "",
  });
  let checkCid: any;
  if (registerInfo?.cidType === "cid") {
    checkCid = checkValidInput({
      ...required["cid"].check,
      text: registerInfo?.cid || "",
    });
  } else {
    if ((registerInfo?.cid || "").length >= 7 && (registerInfo?.cid || "").length <= 9) {
      checkCid = { valid: true };
    } else {
      checkCid = { valid: false, message: errorCheckPassNo };
    }
  }

  if ((!checkCid.valid || !checkUsername.valid) && registerInfo?.error) {
    registerInfo.error.username = checkUsername.message;
    registerInfo.error.cid = checkCid.message;
    isError = true;
  }

  if (
    checkCid.valid &&
    registerInfo?.error &&
    registerInfo?.cidType === "cid"
  ) {
    if (!CheckSumCID(registerInfo?.cid)) {
      registerInfo.error.cid = errorCheckCid;
      isError = true;
    }
  }

  if (viewRegister === "confirmPassword") {
    const checkPassword = checkValidInput({
      ...required["password"].check,
      text: registerInfo?.password || "",
    });
    const checkConfirmPassword = checkValidInput({
      ...required["password"].check,
      text: registerInfo?.confirmPassword || "",
    });

    if (
      (!registerInfo?.password || registerInfo?.password === "") &&
      registerInfo?.error
    ) {
      registerInfo.error.password = errorPassword
      isError = true;
    }

    if (
      (!registerInfo?.confirmPassword ||
        registerInfo?.confirmPassword === "") &&
      registerInfo?.error
    ) {
      registerInfo.error.confirmPassword = errorPassword
      isError = true;
    }

    if (
      (!checkPassword.valid || !checkConfirmPassword.valid) &&
      registerInfo?.error
    ) {
      registerInfo.error.password = checkConfirmPassword.message;
      registerInfo.error.confirmPassword = checkConfirmPassword.message;
      isError = true;
    } else {
      const username = registerInfo?.username
        ?.replace(/\./g, "\\.")
        ?.replace(/\-/g, "\\-");
      const regex = new RegExp(`[${username || ""}]+`, "g");

      const comparePassword =
        registerInfo?.password !== registerInfo?.confirmPassword;
      const intersectUsername =
        (registerInfo?.password?.length || 0) -
          (registerInfo?.password?.match(regex)?.[0]?.length || 0) <=
        0;

      if (comparePassword && registerInfo?.error) {
        registerInfo.error.password = errorPasswordCompare
        registerInfo.error.confirmPassword = errorPasswordCompare
        registerInfo.error.viewIndex = viewRegister;
        isError = true;
      } else if (intersectUsername && registerInfo?.error) {
        registerInfo.error.password = errorIntersectUsername
        registerInfo.error.confirmPassword = errorIntersectUsername
        registerInfo.error.viewIndex = viewRegister;
        isError = true;
      }
    }
  }

  if (isError) {
    controller.setState({ registerInfo: { ...registerInfo }, loading: false });
    return;
  } else if (viewRegister === "username") {
    controller.setState({
      registerInfo: {
        ...registerInfo,
        hidePassword: true,
        hideConfirmPassword: true,
      },
      loading: false,
    });
    HandleUsernameManage(controller, params);
    return;
  } else if (viewRegister === "confirmPassword") {
    history.push("/verify-otp/");
    // HandleAutoFieldOtp(controller, { history });
    return;
  } else if (viewRegister === "verify-otp") {
    HandleCreateUser(controller, { history });
  }
};

const HandleUsernameManage: Handler = async (
  controller,
  params: { history: any }
) => {
  const { registerInfo } = controller.getState();
  const { history } = params;
  controller.setState({ loading: true });

  if (!registerInfo) {
    return;
  }

  const CheckAvailableUser = await CheckAvailableAPIView.post({
    data: {
      username: window.btoa(`${registerInfo?.username}`),
    },
  });

  const CheckAvailableCID = await CheckAvailableCIDAPIView.post({
    data: {
      cid: window.btoa(`${registerInfo?.cid}`),
    },
  });

  // const [[userRes, userE, userN], [cidRes, cidE, cidN]] = await Promise.all([
  //   CheckAvailableUser,
  //   CheckAvailableCID,
  // ]);

  // if ((cidE || userE) && registerInfo.error) {
  //   registerInfo.error.message = userE || cidE;
  //   controller.setState({ registerInfo: { ...registerInfo }, loading: false });
  // }

  const [[userRes, userE, userN]] = await Promise.all([
    CheckAvailableUser,
  ]);

  if ((userE) && registerInfo.error) {
    registerInfo.error.message = userE;
    controller.setState({ registerInfo: { ...registerInfo }, loading: false });
  }

  if (
    !userRes?.available ||
    userRes?.available === null 
    // !cidRes?.available ||
    // cidRes?.available === null
  ) {
    registerInfo.error = {};
    registerInfo.error.username = "USERNAME_AVAILABLE";
    // registerInfo.error.cid = "CID_AVAILABLE";
    controller.setState({
      registerInfo: {
        ...registerInfo,
        error: {
          ...(!userRes?.available && { username: "USERNAME_AVAILABLE" }),
          // ...(!cidRes?.available && { cid: "CID_AVAILABLE" }),
        },
      },
      loading: false,
    });
  } else {
    controller.setState({ loading: false });
    history.push("/create-password/");
  }
};

const HandleCreateUser: Handler = async (
  controller,
  params: { history: any }
) => {
  const { registerInfo } = controller.getState();
  let { history } = params;
  let username = registerInfo?.username || "";
  username = `${username}`;
  let [resToken, errToken]: [any, any] = [null, null];
  controller.setState({ loading: true });

  // registerInfo?.OTPType ต้องแก้หลัง sms otp ให้งานได้
  let data = {
    username: username,
    password: registerInfo?.password,
    confirm_password: registerInfo?.confirmPassword,
    cid: registerInfo?.cid,
    email:
      registerInfo?.OTPType === "email"
        ? registerInfo?.email
        : registerInfo?.phoneNumber,
  };

  const [resRegister, errorRegister] = await RegisterAPI.post({
    data,
  });

  if (resRegister) {
    controller.setState({ refCode: resRegister.ref || "", loading: false });
    if (window.location.pathname.includes("create-password")) {
      history.push("/verify-otp/");
    }
  } else {
    const { registerInfo } = controller.getState();
    let errorMessage = "";
    const error = "This username had already been used.";
    if (
      errorRegister?.email?.[0].includes(error) &&
      errorRegister?.username?.[0].includes(error)
    ) {
      errorMessage = "email หรือ username ถูกใช้งานไปแล้ว";
    } else {
      if (registerInfo?.autoField) {
        errorMessage = "อีเมลนี้ถูกใช้งานในระบบแล้ว กรุณาติดต่อเจ้าหน้าที่เวชระเบียน ของ รพ.";
      } else {
        errorMessage = "อีเมลนี้ถูกใช้งานในระบบแล้ว กรุณาเปลี่ยนอีเมลใหม่สำหรับการลงทะเบียน";
      }
    }
    controller.setState({
      loading: false,
      registerInfo: {
        ...registerInfo,
        error: { ...(errorMessage && { message: errorMessage }) },
      },
    });
    if (window.location.pathname.includes("create-password")) {
      history.push("/verify-otp/");
    }
  }
};

export const HandleRequestToken: Handler = async (
  controller,
  params: { history: any }
) => {
  controller.setState({
    loading: true,
  });
  const { registerInfo, refCode, device_id, uiState } = controller.getState();
  let [resToken, errToken]: [any, any] = [null, null];
  let deviceDetail: any;
  const { history } = params;

  const device = JSON.parse(deviceDetail || "{}");
  var data = {
    username: registerInfo?.username,
    password: registerInfo?.password,
  };

  [resToken, errToken] = await RequestToken.post({
    data: data,
  });

  if (errToken) {
    controller.setState({
      registerInfo: {
        ...registerInfo,
        error: {
          message: errToken?.detail || "error",
        },
      },
      loading: false,
    });
    return;
  } else {
    controller.setState({ refCode: resToken.ref || "", loading: false });
    return;
  }
};

export const HandleRequestTokenWithEmail: Handler = async (
  controller,
  params: { history: any }
) => {
  controller.setState({
    loading: true,
  });
  const { registerInfo, refCode, device_id, uiState } = controller.getState();
  let [resToken, errToken]: [any, any] = [null, null];
  let deviceDetail: any;
  const { history } = params;
  if (typeof window.MobNative !== "undefined") {
    deviceDetail = window.MobNative?.getDevice?.();
  } else if (typeof window.iosNative !== "undefined") {
    deviceDetail = await window.iosNative?.getDevice;
  }

  const device = JSON.parse(deviceDetail || "{}");
  var data = {
    username: registerInfo?.username,
    ref: refCode,
    password: registerInfo?.otpCode || "",
    email:
      registerInfo?.OTPType === "email"
        ? registerInfo?.email
        : registerInfo?.phoneNumber,
    ...device,
    application: device?.application || "ishealth",
    device_id: device_id,
  };

  [resToken, errToken] = await RequestTokenWithEmail.post({
    data: data,
  });
  if (errToken) {
    controller.setState({
      registerInfo: {
        ...registerInfo,
        error: {
          message: errToken?.detail || "error",
        },
      },
      loading: false,
    });
    return;
  } else {
    Cookies.set("apiToken", resToken.token);
    controller.apiToken = resToken.token;
    const [res,error] = await GetUserProfile(controller,{apiToken:resToken.token})
    controller.setState(
      {
        apiToken: resToken.token,
        userId: resToken.user_id,
        loading: false,
        profileInfo: {
          cid: resToken.cid,
        },
      },
      () => {
        if (uiState === "LOGIN") {
          gotoMainScreen(history);
        } else {
          HandleCheckConsent(controller, params);
        }
      }
    );
    saveDataLogin(controller, {
      apiTokenP: resToken.token || "",
      userIdP: resToken.user_id || "",
      patient: res.patient|| 0
    });
    Cookies.set("userId", resToken.user_id);
  }
};

export const GetUserProfile: Handler = async (controller, params) => {
  const state = controller.getState();
  const [res, error] = await UserProfileAPI.get({
    apiToken: params.apiToken,
  });
  if (res) {
    let editDob = (res?.dob || "").split("/");
    controller.setState({
      profileInfo: {
        ...state.profileInfo,
        ...res,
        cid: state.registerInfo?.cid,
        dob: editDob && `${editDob?.[2]}${editDob?.[1]}${editDob?.[0]}`,
      },
    });
  }

  return [res, error];
};

export const HandleUpdateUserProfile: Handler = async (controller, params) => {
  const { apiToken, profileInfo, dropDownOption } = controller.getState();
  controller.setState({
    loading: true,
  });

  if (["VERIFIED"].includes(profileInfo?.profile_status || "")) {
    gotoMainScreen(params.history);
    return;
  }

  const errorArray = ["undefined", "ไม่รู้"];

  if (
    !profileInfo?.pre_name ||
    errorArray.includes(profileInfo?.pre_name) ||
    !profileInfo?.dob ||
    errorArray.includes(profileInfo?.gender || "") ||
    !profileInfo?.first_name ||
    !profileInfo?.last_name ||
    !profileInfo?.gender ||
    !profileInfo?.nationality ||
    !profileInfo?.email ||
    !profileInfo?.phone_no
  ) {
    controller.setState({
      loading: false,
      profileInfo: {
        ...profileInfo,
        error: {
          ...((!profileInfo?.pre_name ||
            errorArray.includes(profileInfo?.pre_name)) && {
            pre_name: "error",
          }),
          ...(!profileInfo?.first_name && { first_name: "error" }),
          ...(!profileInfo?.last_name && { last_name: "error" }),
          ...(!profileInfo?.dob && { dob: "error" }),
          ...(!profileInfo?.nationality && { nationality: "error" }),
          ...((!profileInfo?.gender ||
            errorArray.includes(profileInfo?.gender)) && { gender: "error" }),
          ...(!profileInfo?.email && { email: "error" }),
          ...(!profileInfo?.phone_no && { phone_no: "error" }),
        },
      },
    });
    return;
  }

  const [res, error] = await UserProfileAPI.patch({
    apiToken: apiToken || Cookies.get("apiToken"),
    data: {
      pre_name: profileInfo?.pre_name,
      first_name: profileInfo?.first_name,
      last_name: profileInfo?.last_name,
      gender: profileInfo?.gender,
      dob: moment(profileInfo?.dob, "YYYYMMDD").format("YYYY-MM-DD"),
      nationality: profileInfo?.nationality,
      phone_no: profileInfo?.phone_no,
      email: profileInfo?.email,
    },
  });

  if (res) {
    controller.setState({
      loading: false,
    });
    gotoMainScreen(params.history);
  } else {
    controller.setState({
      loading: false,
    });
    console.log("error ", error);
  }
};

export const PostRequestTokenAPI: Handler = async (controller, params) => {
  const { setDevice } = controller.getState();
  let deviceDetail: any;

  if (window.MobNative?.getDevice) {
    deviceDetail = window.MobNative?.getDevice?.();
  } else if (window.iosNative?.getDevice) {
    deviceDetail = await window.iosNative?.getDevice;
  } else {
    deviceDetail = JSON.stringify(setDevice || {});
  }

  const device = JSON.parse(deviceDetail || "{}");

  const [res, error] = await RequestToken.post({
    data: {
      ...params,
      device_api_version: "20211219",
      application: device?.application || "ishealth",
      // device_id: "6cd4d53686e8b641beb5cbffda92429e",
      // device_name: "Chrome on Mac OS X",
      // device_token: "{}",
      // device_type: "webpush",
      ...(device || {}),
    },
  });
  return [res, error];
};

export const HandleSetValueRegisterInfo: Handler = (controller, params) => {
  let { registerInfo } = controller.getState();
  const { key, value } = params;

  if (registerInfo) {
    (registerInfo as any)[key] = value;
  } else {
    registerInfo = {} as any;
    (registerInfo as any)[key] = value;
  }

  controller.setState({
    registerInfo: {
      ...(registerInfo || ({} as any)),
      error: registerInfo?.error || ({} as any),
    },
  });
};

export const HandleSetLoginInfo: Handler = (controller, params) => {
  let { loginInfo } = controller.getState();
  const { key, value } = params;

  if (loginInfo) {
    (loginInfo as any)[key] = value;
  } else {
    loginInfo = {} as any;
    (loginInfo as any)[key] = value;
  }

  controller.setState({
    loginInfo: {
      ...(loginInfo || ({} as any)),
    },
  });
};

export const HandleSetProfileInfo: Handler = (controller, params) => {
  let { profileInfo } = controller.getState();
  const { key, value } = params;

  if (profileInfo) {
    (profileInfo as any)[key] = value;
  } else {
    profileInfo = {} as any;
    (profileInfo as any)[key] = value;
  }

  controller.setState({
    profileInfo: {
      ...(profileInfo || ({} as any)),
    },
  });
};

export const HandleCheckConsent: Handler = async (controller, params) => {
  let { history, readOnly } = params;
  GetPatientDetail(controller, params);
  HandleGetUserProfile(controller, params);

  const [res, error] = await HandleCheckStateConsent(controller, params);

  if (!readOnly) {
    if (res.finished === false && res) {
      controller.setState({
        loading: false,
      });
      history.push(`/consent-form/${res.publish_diag_rule}`);
    } else {
      controller.setState({
        loading: false,
      });
      saveDataLogin(controller, {});
      HandleCheckProfile(controller, params);
    }
  }
};

export const HandleAutoFieldOtp: Handler = async (controller, params) => {
  const { apiToken, registerInfo } = controller.getState();
  let { history } = params;

  controller.setState({
    loading: true,
  });

  let data = {};
  if (registerInfo?.cidType !== "cid") {
    data = {
      passport_number: registerInfo?.cid,
    };
  } else {
    data = {
      citizen_number: registerInfo?.cid,
    };
  }

  const [res, error] = await GetDataForOtp.post({
    data: data,
  });

  if (res) {
    // ต้องแก้หลัง sms otp ให้งานได้
    controller.setState(
      {
        registerInfo: {
          ...registerInfo,
          autoField: true,
          OTPType: "email",
          ...(res.mobile && { phoneNumber: res.mobile }),
          ...(res.email && { email: res.email }),
        },
        loading: false,
      },
      () => {
        controller.handleEvent({
          message: "HandleRegister",
          params: { viewRegister: "verify-otp", history: history },
        });
      }
    );
  } else {
    controller.setState({
      registerInfo: {
        ...registerInfo,
        autoField: false,
      },
      loading: false,
    });
    history.push("/verify-otp/");
  }
};

export const HandleAutoHn: Handler = async (controller, params) => {
  const { apiToken, profileInfo, uiState } = controller.getState();
  let { history } = params;

  if (uiState === "CONSENT") {
    controller.setState({
      uiState: "LOGIN",
    });
    gotoMainScreen(history);
    return;
  }


  HandleCheckProfile(controller, {...params, forceEditProfile: true});
  // let data = {};
  // if (CheckSumCID(profileInfo?.cid)) {
  //   data = {
  //     patient_id: profileInfo?.patient,
  //     citizen_number: profileInfo?.cid,
  //   };
  // } else {
  //   data = {
  //     patient_id: profileInfo?.patient,
  //     passport_number: profileInfo?.cid,
  //   };
  // }

  // const [res, error] = await AutoMatchORCreateHNFromCID.post({
  //   data: data,
  //   apiToken: apiToken,
  // });
  // saveDataLogin(controller, {});
  // if (res) {
  //   HandleCheckProfile(controller, {...params, forceEditProfile: true});
  // } else {
  //   HandleCheckProfile(controller, {...params, forceEditProfile: true});
  // }
};

export const HandleGetUserProfile: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();

  controller.setState({
    loading: true,
  });

  const options = await GetMasterOptions(controller, params);

  const dropDownOption: dropDownOption = {
    genders: [...options.gender.th, ...options.gender.en],
    prenames: [...options.prename.th, ...options.prename.en],
    nationalities: [...options.nationality.th, ...options.nationality.en],
  };
  const [res, error] = await GetUserProfile(controller, {
    apiToken: apiToken || Cookies.get("apiToken"),
  });

  if (res) {
    controller.setState({
      profileInfo: FormattedProfileInfo({
        profile: res,
        options: dropDownOption,
        lang: params.lang,
      }),
      dropDownOption: dropDownOption,
      loading: false,
    });
  } else {
    controller.setState({
      profileInfo: {},
      dropDownOption: dropDownOption,
      loading: false,
    });
  }

  // CheckHnFromHospital(controller, params);
};

const HandleCheckProfile: Handler = async (controller, params) => {
  const { apiToken, uiState } = controller.getState();
  controller.setState({
    loading: true,
  });
  let { history ,forceEditProfile} = params;

  GetPatientDetail(controller, params);

  const [res, error] = await GetUserProfile(controller, {
    apiToken: apiToken || Cookies.get("apiToken"),
  });

  if (res) {
    var hasProfile = false;

    if (res.first_name && res.last_name && res.cid && res.dob && res.email) {
      controller.setState({
        loading: false,
        profileInfo: { ...res },
      });
      hasProfile = true;
    }

    if (hasProfile && !forceEditProfile) {
      controller.setState({
        loading: false,
      });
      gotoMainScreen(history);
    } else {
      controller.setState({
        loading: false,
      });
      history.push("/edit-profile/");
    }
  } else {
    controller.setState({
      loading: false,
    });
    history.push("/edit-profile/");
  }
};

export const HandleLogin: Handler = async (controller, params) => {
  const { loginInfo, registerInfo } = controller.getState();
  controller.setState({
    uiState: "LOGIN",
  });
  let username = loginInfo?.username || "";
  let password = loginInfo?.password || "";
  username = `${username}`;
  const login = { username, password };
  controller.setState({ loading: true });
  const { history } = params;

  if (!loginInfo?.password || !loginInfo?.username) {
    controller.setState({
      loading: false,
      loginInfo: {
        ...loginInfo,
        error: {
          ...(!loginInfo?.password && { password: "กรุณากรอก password" }),
          ...(!loginInfo?.username && { username: "กรุณากรอก username" }),
        },
      },
    });
    return;
  }

  const [resToken, errToken] = await PostRequestTokenAPI(controller, login);
  if (resToken?.waiting_set_device) {
    return;
  }
  if (resToken) {
    Cookies.set("apiToken", resToken.token || "");
    controller.apiToken = resToken.token || "";
    const [res,error] = await GetUserProfile(controller,{apiToken:resToken.token})
    controller.setState(
      {
        apiToken: resToken.token || "",
        userId: resToken.user_id,
        refCode: resToken.ref || "",
        loading: false,
        registerInfo: {
          ...registerInfo,
          ...resToken,
          ...(resToken.email && { OTPType: "email" }),
        },
      },
      () => {
        if (!resToken.token && resToken.ref) {
          history.push("/verify-otp/");
        } else {
          // HandleCheckConsent(controller, params);
          gotoMainScreen(history);
        }
      }
    );
    console.log("saika ~ res:", res)
    saveDataLogin(controller, {
      apiTokenP: resToken.token || "",
      userIdP: resToken.user_id || "",
      patient: res.patient|| 0
    });
    Cookies.set("userId", resToken.user_id);
  } else {
    controller.setState({
      loading: false,
      loginInfo: {
        ...loginInfo,
        error: {
          ...(errToken && { message: "ชื่อผู้ใช้งาน หรือ รหัสผ่านไม่ถูกต้อง" }),
        },
      },
    });
    return;
  }
};

export const HandleResetPassword: Handler = async (controller, params) => {
  const { registerInfo, apiToken } = controller.getState();
  const { history , email} = params;
  controller.setState({
    loading: true,
  });

  const [resetPassResp, resetPassError] = await ResetPasswordAPI.post({
    data: { email: email },
  });

  if (resetPassResp) {
    controller.setState({
      loading: false,
      errorMessage: "success",
    });
  } else {
    controller.setState({
      errorMessage: "error",
      loading: false,
    });
  }

  console.log("saika ~ resetPassResp:", resetPassResp);
  console.log("saika ~ resetPassError:", resetPassError);
};

export const HandleSignOut: Handler = async (controller, params) => {
  controller.setState({
    chatUnread: 0
  });
  const [res, error] = await MobileDeviceSignOutAPI.get({
    apiToken: Cookies.get("apiToken"),
  });
};

export const GetPatientDetail: Handler = async (controller, params) => {
  const [res, error] = await ProxyUserProfileAPI.get({
    apiToken: controller.cookies.get("apiToken"),
  });

  if (res) {
    console.log("saika ~ res:", res);
    controller.setState({ patientData: { ...res, id: res.patient } || {} });
  }
};

////////////////////////////////// appointment //////////////////////////////////////

export const HandleCreateAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  // check before and after 3 hr
  if (!params?.repetition_note) {
    const [response, error, network]: any = await PatientAppointmentView.list({
      apiToken: apiToken,
      params: {
        patient_id: params?.patientId,
        status: params?.viewIndex === 1 ? 8 : null,
      },
    });
  
    let duplicateList: any[] = [];
  
    (response.items || []).forEach((item:any)=>{
      const currentAppointment = moment(params?.date)
      const listAppointment = moment(item.estimated_at_iso)
      if (currentAppointment.diff(listAppointment,'days') === 0) {
        if (currentAppointment.diff(listAppointment,'hours',true) >= -3 && currentAppointment.diff(listAppointment,'hours',true) <= 3) {
          console.log("appointment in 3 hr. can't create:", item)
          duplicateList.push(item)
        }
      }
    })
  
    if (duplicateList.length > 0 && !params?.repetition_note) {
      controller.setState({
        duplicateAPMList: duplicateList,
      });
      return
    }
  }
  

  const [r, e, n] = await PatientAppointmentView.create({
    data: {
      division: state.appointmentData?.doctor?.division,
      patient: state.patientData?.id,
      division_service_block: state.appointmentData?.doctor?.id,
      order_note: state.appointmentData?.detail,
      estimated_at_iso: params?.date,
      estimated_duration: state.appointmentData?.doctor?.slot_length,
      repetition_note: params?.repetition_note ? params?.repetition_note :"",
      emergency_contact_first_name: state.appointmentData?.personName,
      emergency_contact_last_name: state.appointmentData?.personLastName,
      emergency_contact_telephone_number: state.appointmentData?.personPhone,
      is_telemed: params.is_telemed,
    },
    apiToken: apiToken,
  });

  // const [response, error] = await ProxyPatientEmergencyContactView.post({
  //   data: {
  //     ecp_first_name: state.appointmentData?.personName || "",
  //     ecp_last_name: state.appointmentData?.personLastName || "",
  //     ecp_phone_no: state.appointmentData?.personPhone || "",
  //   },
  //   apiToken: apiToken,
  // });

  if (r) {
    if (r.is_telemed) {
      AppointmentDetail.update({
        pk: r.content_id,
        apiToken: apiToken,
        data: { action: "EDIT", is_telemed: true, patient: r.patient } as any,
        extra: { division: r.division },
      });
    }

    controller.setState({
      successMessage: "บันทึกสำเร็จ",
      duplicateAPMList: []
    });
  } else {
    controller.setState({
      errorMessage: "บันทึกไม่สำเร็จ",
      duplicateAPMList: []
    });
  }
};

export const HandleGetAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  // console.log("HandleGetAppointment: ", params)
  const [r, e, n] = await PatientAppointmentUpdate.retrieve({
    pk: params?.id,
    apiToken: apiToken,
  });

  if (r && r.display_info?.provider_object_id) {
    const doctorInfo = await DoctorDetail.retrieve({
      pk: r.display_info.provider_object_id,
      apiToken: apiToken,
    });

    await HandleDoctorDivisionServiceBlockSchedule(controller, {
      doctorID: r?.display_info?.provider_object_id,
      month: moment(r.estimated_at_iso).format("M"),
      year: moment(r.estimated_at_iso).format("YYYY"),
      is_telemed: r.is_telemed,
    });

    return controller.setState({
      appointmentData: {
        ...state.appointmentData,
        time: "",
        // time: moment(r.estimated_at_iso).format("HH:mm"),
        selectDate: moment(r?.estimated_at_iso).format("YYYY-MM-DD"),
        data: { ...r, display_info: { ...r.display_info, ...doctorInfo?.[0] } },
        appointmentTime: moment(r.estimated_at_iso).format("HH:mm"),
      },
    });
  }

  controller.setState({
    appointmentData: {
      ...state.appointmentData,
      data: r,
    },
  });
};

export const HandleUpdateAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentUpdate.patch({
    pk: state.appointmentData?.data?.id,
    data: {
      division: state.appointmentData?.doctor?.division,
      patient: state.patientData?.id,
      division_service_block: state.appointmentData?.doctor?.id,
      order_note: state.appointmentData?.data?.order_note,
      estimated_at_iso: params?.date,
      estimated_duration: state.appointmentData?.doctor?.slot_length,
      repetition_note: "",
      status_note: state.appointmentData?.postponeDetail,
      emergency_contact_first_name: state.appointmentData?.personName,
      emergency_contact_last_name: state.appointmentData?.personLastName,
      emergency_contact_telephone_number: state.appointmentData?.personPhone,
      is_telemed: params.is_telemed,
    },
    apiToken: apiToken,
  });

  // const [response, error] = await ProxyPatientEmergencyContactView.post({
  //   data: {
  //     ecp_first_name: state.appointmentData?.personName || "",
  //     ecp_last_name: state.appointmentData?.personLastName || "",
  //     ecp_phone_no: state.appointmentData?.personPhone || "",
  //   },
  //   apiToken: apiToken,
  // });

  if (r) {
    controller.setState({
      successMessage: "บันทึกสำเร็จ",
    });
  } else {
    controller.setState({
      errorMessage: "บันทึกไม่สำเร็จ",
    });
  }
};

export const HandleCancelAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentUpdate.patch({
    pk: params?.id,
    data: {
      status: 5,
      status_note: params.note
    },
    apiToken: apiToken,
  });

  if (r) {
    const [response, error, network]: any = await PatientAppointmentView.list({
      apiToken: apiToken,
      params: {
        patient_id: params?.patientId,
        status: params?.viewIndex === 1 ? 8 : null,
      },
    });

    controller.setState({
      patientAppointmentList: response,
    });
  }
};

export const HandleConfirmAppointment: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [r, e, n] = await PatientAppointmentUpdate.patch({
    pk: params?.id,
    data: {
      status: 2,
      [params.selectedItem.id]: params.selectedItem.doctor_full_name,
    },
    apiToken: apiToken,
  });

  if (r) {
    const [response, error, network]: any = await PatientAppointmentView.list({
      apiToken: apiToken,
      params: {
        patient_id: params?.patientId,
        status: params?.viewIndex === 1 ? 8 : null,
      },
    });

    controller.setState({
      patientAppointmentList: response,
    });
  }
};

export const HandlePatientAppointmentView: Handler = async (
  controller,
  params
) => {
  const { apiToken } = controller.getState();
  const state = controller.getState();

  const [response, error, network]: any = await PatientAppointmentView.list({
    apiToken: apiToken,
    params: {
      patient_id: params?.patientId,
      status: params?.viewIndex === 1 ? 8 : null,
    },
  });

  controller.setState({
    patientAppointmentList: response,
  });
};

export const HandleDoctorDivisionServiceBlockSchedule: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const { apiToken } = controller.getState();

  const [r, e, n] = await DoctorDivisionServiceBlockSchedule.list({
    params: {
      division: params?.divisionID,
      doctor: params?.doctorID,
      month: params?.month,
      year: params?.year,
      is_telemed: params?.is_telemed,
    },
    apiToken: apiToken,
  });

  controller.setState({
    createAppointment: {
      ...state?.createAppointment,
      doctorDivisionServiceBlockSchedule: r?.items,
    },
  });
};

export const HandleDivisionList: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();

  const [r, e, n] = await DivisionList.list({
    params: {
      ...(params?.only_have_dsb ? { only_have_dsb: params.only_have_dsb } : {}),
      // limit: 99999,
      // for_order_div: true,
    },
    apiToken: apiToken,
  });

  controller.setState({
    divisionList: r?.items,
    appointmentData: {
      detail: params?.detail,
    },
  }, () => {HandleDoctorList(controller, params)});
};

export const HandleChangePasswordApi: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  controller.setState({
    loading: true,
  });

  const [r, e, n] = await UserChangePasswordAPI.post({
    data: {
      password: params.password,
      new_password: params.new_password,
      confirm_password: params.confirm_password,
    },
    apiToken: apiToken,
  });

  if (n.status === 204) {
    controller.setState({
      errorMessage: "success",
      loading: false,
    });
  } else {
    controller.setState({
      errorMessage: "error",
      loading: false,
    });
  }
};

export const HandleDoctorList: Handler = async (controller, params) => {
  const { apiToken, divisionList } = controller.getState();

  const [r, e, n] = await DoctorDSBAvailableList.list({
    apiToken: apiToken,
    params: {
      ...(params?.search ? { name_code: params.search } : {}),
      ...(params?.isTelemed ? { is_telemed: true } : {}),
    },
  });

  const doctor = (r?.items || []).map((item: any)=>{
      const findDivision = (item.divisions || []).map((division: any)=> {
        return  (divisionList || []).find((itemDivision:any) => itemDivision.id === division)
      })

      return {...item, divisions: findDivision}
  })

  controller.setState({
    doctorList: doctor,
  });
};

export const HandleGetTimeSlotByDate: Handler = async (controller, params) => {
  const { apiToken, createAppointment, userProfile, appointmentData } =
    controller.getState();
  const [response, error, network]: any = await PatientAppointmentView.list({
    apiToken: apiToken,
    params: {
      status: 1,
      start_date: params.selectDate
        ? dateToStringWithoutTimeBE(params.selectDate)
        : "",
      end_date: params.selectDate
        ? dateToStringWithoutTimeBE(params.selectDate)
        : "",
    },
  });

  let dsbData: any[] = [];
  const filterDSB = createAppointment?.doctorDivisionServiceBlockSchedule?.filter((item:any)=> moment(item?.start_datetime_iso, "YYYY-MM-DD").format("YYYY-MM-DD") ===
  params.selectDate)

  filterDSB?.forEach((item:any) => {
    dsbData.push(item.id)
  });

  const [resDSB, errorDSB]: any = await DivisionServiceBlockAvailableList.create({
    apiToken: apiToken,
    data: {"dsb": dsbData}
  });

  // console.log("HandleGetTimeSlotByDate: ", response)
  let reservedTimeByDSB: any = {};
  response?.items
    ?.filter((item: any) => userProfile?.patient !== item.patient)
    .forEach((item: any) => {
      //  console.log("reservedTimeByDSB: ", reservedTimeByDSB, item.estimated_at_iso)
      if (!(item.division_service_block in reservedTimeByDSB)) {
        reservedTimeByDSB[item.division_service_block] = [
          moment(item.estimated_at_iso).format("HH:mm"),
        ];
      } else {
        reservedTimeByDSB[item.division_service_block].push(
          moment(item.estimated_at_iso).format("HH:mm")
        );
      }
    });
  let reservedTimeByPatient =
    response?.items
      ?.filter(
        (item: any) =>
          userProfile?.patient === item.patient &&
          !(
            appointmentData.division_service_block ===
              item.division_service_block &&
            moment(item.estimated_at_iso).format("HH:mm") === params?.selectTime
          )
      )
      .map((item: any) => moment(item.estimated_at_iso).format("HH:mm")) || [];

  // console.log("reservedTimeByPatient: ", reservedTimeByPatient, "reservedTimeByDSB", reservedTimeByDSB)
  let dataTime: any[] = [];
  let dataTimeAndDoctorDSB: any[] = [];

  createAppointment?.doctorDivisionServiceBlockSchedule?.forEach(
    (item: any) => {
      if (
        moment(item?.start_datetime_iso, "YYYY-MM-DD").format("YYYY-MM-DD") ===
        params.selectDate && resDSB.some((itemDSB:any)=> itemDSB.id === item.id)
      ) {
        //set text for date time
        let textTime = `${moment(item?.start_datetime_iso).format(
          "HH:mm"
        )} - ${moment(item?.end_datetime_iso).format("HH:mm")}`;
        if (dataTime.length === 0) {
          dataTime.push(textTime);
          dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] });
        } else if (dataTime.length !== 0 && !dataTime.includes(textTime)) {
          dataTime.push(textTime);
          dataTimeAndDoctorDSB.push({ periodTime: textTime, doctorDSB: [] });
        }

        let indOfDataTime = dataTime.indexOf(textTime);
        // set selectable time
        let timeData =
          ((parseFloat(moment(item?.end_datetime_iso).format("H")) -
            parseFloat(moment(item?.start_datetime_iso).format("H"))) /
            item?.slot_length) *
          60;
        let timeValue: any[] = [];
        
        let timeNow = moment().format("HH:mm");
        const toDate = moment().format("YYYY-MM-DD");
        
        for (let i = 0; i < timeData; i++) {
          let time = moment(item?.start_datetime_iso)
            .add(item?.slot_length * i, "minutes")
            .format("HH:mm");
          if (toDate === moment(item?.start_datetime_iso).format("YYYY-MM-DD")) {
            if (
              !reservedTimeByDSB?.[item.id]?.includes(time) &&
              !reservedTimeByPatient?.includes(time) && timeNow < time
            ) {
              timeValue.push(time);
            }
          } else if (
            !reservedTimeByDSB?.[item.id]?.includes(time) &&
            !reservedTimeByPatient?.includes(time)
          ) {
            timeValue.push(time);
          }
        }
         
        dataTimeAndDoctorDSB[indOfDataTime]["doctorDSB"].push({
          ...item,
          timeValue: timeValue,
        });
      }
    }
  );

  controller.setState({
    timeDivision: dataTimeAndDoctorDSB,
  });
};

export const HandleDivisionScheduleTimeList: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  const { apiToken } = controller.getState();

  const [r, e, n] = await DivisionScheduleTimeList.list({
    params: {
      division: params?.divisionID,
      selected_date: params?.selectDate,
    },
    apiToken: apiToken,
  });

  const [res, error] = await UserProfileAPI.get({
    apiToken: apiToken,
  });

  controller.setState({
    createAppointment: {
      ...state?.createAppointment,
      divisionScheduleTimeList: r?.items,
      userProfile: res,
    },
    appointmentData: {
      ...state.appointmentData,
      personPhone: res?.ecp_phone_no,
      personLastName: res?.ecp_last_name,
      personName: res?.ecp_first_name,
    },
  });
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */

const gotoMainScreen = (history: any) => {
  history.push("/");
};

const HandleCheckStateConsent: Handler = async (controller, params) => {
  const { apiToken } = controller.getState();
  const division = 1;
  const code = "CS";

  const [res, error] = await CheckRequireDiagRule.get({
    params: { division: division, code: code },
    apiToken: apiToken,
  });

  controller.setState({
    consentFinished: res?.finished || false,
  });

  return [res, error];
};

const saveDataLogin: Handler = async (controller, params) => {
  const { apiToken, userId, profileInfo } = controller.getState();
  const { apiTokenP, userIdP , patient } = params;

  const token = apiTokenP || apiToken;
  const user_id = userIdP || userId;
  if (window.MobNative?.saveDataLogin) {
    window.MobNative.saveDataLogin(token, `${user_id}`,`${profileInfo?.patient || patient}`);
  } else if (window.iosNative?.saveDataLogin) {
    window.iosNative?.saveDataLogin(token, `${user_id}`);
  }
};

const GetMasterOptions: Handler = async (controller, params) => {
  const gatGender = (lang: string) => {
    return ProfileApi.get({
      apiToken: controller.cookies.get("apiToken"),
      pk: "gender",
      extra: { headers: { "Accept-Language": lang } },
    });
  };

  const gatPrename = (lang: string) => {
    return ProfileApi.get({
      apiToken: controller.cookies.get("apiToken"),
      pk: "prename",
      params: { all: "true" },
      extra: { headers: { "Accept-Language": lang } },
    });
  };

  const gatNationality = (lang: string) => {
    return ProfileApi.get({
      apiToken: controller.cookies.get("apiToken"),
      pk: "nationality",
      extra: { headers: { "Accept-Language": lang } },
    });
  };

  const [
    [genderTh],
    [genderEn],
    [prenameTh],
    [prenameEn],
    [nationalityTh],
    [nationalityEn],
  ] = await Promise.all([
    gatGender("th"),
    gatGender("en"),
    gatPrename("th"),
    gatPrename("en"),
    gatNationality("th"),
    gatNationality("en"),
  ]);
  return {
    gender: { th: genderTh?.items || [], en: genderEn?.items || [] },
    prename: { th: prenameTh?.items || [], en: prenameEn?.items || [] },
    nationality: {
      th: nationalityTh?.items || [],
      en: nationalityEn?.items || [],
    },
  };
};

const CheckSumCID = (cidString: any) => {
  if (cidString?.length === 13) {
    const lastDigitCid = cidString?.slice(
      cidString.length - 1,
      cidString.length
    );
    const cidArray = cidString?.slice(0, cidString.length - 1).split("");
    const sum = cidArray.reduce((total: number, digit: string, i: number) => {
      return total + (13 - i) * +digit;
    }, 0);

    const lastDigitCal = `${(11 - (sum % 11)) % 10}`;
    return lastDigitCid === lastDigitCal;
  } else {
    return false;
  }
};

const FormattedProfileInfo = (params: any) => {
  let email = "";
  const { profile, options } = params;
  const prename = (options.prenames || []).filter((item: any) => {
    return (
      item?.name === profile?.pre_name || item?.name_en === profile?.pre_name
    );
  });

  return {
    ...profile,
    pre_name: prename[0]?.id || 0,
    dob: profile.birthdate
      ? moment(profile.birthdate).format("YYYYMMDD")
      : moment(),
  };
};

const findOptionByName = (params: {
  th: any[];
  en: any[];
  value: string;
  lang: string;
}) => {
  const { th, en, value } = params;

  const items = { th, en } as any;
  const invert = { th: "en", en: "th" } as any;

  const compareUpperCase = (text1: string, text2: string) => {
    return text1?.toUpperCase() === text2?.toUpperCase();
  };

  const data = Array(en.length)
    .fill("")
    .flatMap((_, index) => {
      if (compareUpperCase(en[index]?.name, value)) {
        return [{ index, lang: "en" }];
      } else if (compareUpperCase(th[index]?.name, value)) {
        return [{ index, lang: "th" }];
      } else {
        return [];
      }
    })[0];
  const lang = params.lang === data?.lang ? data.lang : invert[data?.lang];

  return data?.lang ? items[lang][data.index].name : value;
};

const checkValidInput = (params: {
  text: string;
  prefix: string[];
  minLength?: number;
  length?: number;
  message?: string;
}): { valid: boolean; message?: any } => {
  const {
    text = "",
    prefix = [],
    minLength = 0,
    length = 0,
    message = "",
  } = params;
  if (minLength && text.length < minLength) {
    return { valid: false, message };
  }

  if (length && text.length !== length) {
    return { valid: false, message };
  }

  let errorKey: string = "";

  const validAll = prefix.every((value) => {
    const regex = new RegExp(`[${value}]`, "g");
    const test = regex.test(text);
    if (!errorKey && !test) {
      errorKey = value;
    }
    return test;
  });

  return { valid: validAll, message: validAll ? "" : message };
};
